import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { Close as CloseIcon } from '@mui/icons-material'
import { IconButton, Paper, Tooltip, Typography } from '@mui/material'
import AccessibilityStatementPage from '../AccessibilityStatementPage'
import AccountDeregisterPage from '../AccountDeregisterPage'
import AccountDetailsPage from '../AccountDetailsPage'
import AccountEmailsPage from '../AccountEmailsPage'
import AccountIndividualsPage from '../AccountIndividualsPage'
import AccountLocationsPage from '../AccountLocationsPage'
import AccountNotificationsPage from '../AccountNotificationsPage'
import AccountPasswordPage from '../AccountPasswordPage/AccountPasswordPage'
import AccountPhonesPage from '../AccountPhonesPage'
import AccountGroupsPage from '../AccountGroupsPage/AccountGroupsPage'
import FaqPage from '../FaqPage/FaqPage'
import LoginPage from '../LoginPage'
import PasswordRequestPage from '../PasswordRequestPage'
import PasswordResetPage from '../PasswordResetPage'
import RegisterPage from '../RegisterPage'
import SitemapPage from '../SitemapPage'

import intl from '../../helper/intl'

import { useDispatch, useSelector } from 'react-redux'
import { loadAllAccountDataAsync } from '../../actions/accountAction'

import { MobileView } from 'react-device-detect'
import {
  companyDataChange,
  loginCredentialsChange,
  loginFailure,
  loginStart,
  loginSuccess,
  passwordRequestDataChange,
  registerCredentialsChange
} from '../../actions/authAction'
import { closeAppSnackbar, openAppSnackbar } from '../../actions/notificationsAction'
import utils from '../../helper/utils'
import authService from '../../service/authService'
import config from '../../service/config'
import useStyles from './AppBody.styles'

// NOTE: This component should be the only one in the App to handle routing and route-related changes
const AppBody = () => {
  const loginCredentials = useSelector((state) => state.auth.loginCredentials)
  const registerCredentials = useSelector((state) => state.auth.registerCredentials)
  const passwordRequestData = useSelector((state) => state.auth.passwordRequestData)
  const [isHidden, setIsHidden] = useState(false)
  const accountId = useSelector((state) => state.auth.accountId)
  const companyData = useSelector((state) => state.auth.companyData)
  const accountDetailsData = useSelector((state) => state.accountDetails.details)
  const accountDetailsLoading = useSelector((state) => state.accountDetails.detailsLoading)
  const accountLocationsData = useSelector((state) => state.accountLocations.locations)

  const urlBasename = useSelector((state) => state.intl.urlBasename)
  const language = useSelector((state) => state.intl.language)
  const location = useLocation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const missingAccountData = useMemo(() => {
    return utils.getMissingAccountData(companyData, { accountDetailsData, accountLocationsData })
  }, [companyData, accountDetailsData, accountLocationsData])

  // If company data is not defined in the application state, take it from window.companyData
  const _companyData = Object.prototype.hasOwnProperty.call(companyData, 'portal_registration_enabled')
    ? companyData
    : window.companyData || {}

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search || '')
    const access_token = searchParams.get('portal_id')

    if (access_token) {
      dispatch(loginStart())

      axios
        .post(config.apiUrl + '/target/login', null, {
          headers: { Authorization: 'Bearer ' + access_token }
        })
        .then((response) => {
          sessionStorage.setItem('pathnameAfterLogin', location.pathname)
          dispatch(
            loginSuccess({
              accountId: response.data.id,
              accessToken: access_token,
              refreshToken: ''
            })
          )
        })
        .catch((error) => {
          dispatch(loginFailure({ error: intl.translate('login_page__login_error') }))
          dispatch(
            openAppSnackbar({
              message: intl.getServerError(error.response.data) || intl.translate('login_page__login_error'),
              actionText: intl.translate('general__retry'),
              actionFunction: () => {
                dispatch(closeAppSnackbar())
                document.getElementById('login-page--submit-btn').click() // re-submit the form instead of calling onLoginFormSubmit
              }
            })
          )
        })
    }

    applyDefaultPrefix()
  }, [])

  useEffect(() => {
    // Whenever the login state changes, update the data-logged-in attribute of the body
    document.body.setAttribute('data-logged-in', accountId ? 'true' : 'false')

    if (accountId) {
      // Redirect to pathnameAfterLogin, if the value is set
      const pathnameAfterLogin = sessionStorage.getItem('pathnameAfterLogin')
      if (pathnameAfterLogin) {
        sessionStorage.removeItem('pathnameAfterLogin')
        history.push(pathnameAfterLogin)
      }

      // Load all account data after login (and also, when the page loads and there's an account ID)
      dispatch(loadAllAccountDataAsync({ accountId }))
    }
  }, [dispatch, accountId])

  useEffect(() => {
    // Whenever the pathname changes, update the data-pathname attribute of the body
    let pathname = location.pathname.toLowerCase()
    pathname = pathname.replace(urlBasename, '')

    if (!pathname || pathname === '/') {
      pathname = '/index'
    }

    document.body.setAttribute('data-pathname', pathname)
  }, [location, urlBasename])

  const applyDefaultPrefix = () => {
    authService.getCompanyData().then((result) => {
      const code =
        result?.data?.default_country_code?.dialCode === '+1'
          ? result?.data?.default_country_codetext || 'US'
          : result?.data?.default_country_code?.code || 'US'

      const newLoginCredentials = { ...loginCredentials }
      const newFormData = { ...passwordRequestData }
      const newRegisterCredentials = { ...registerCredentials }

      newLoginCredentials.countryCode = code
      newFormData.countryCode = code
      newRegisterCredentials.countryCode = code

      dispatch(loginCredentialsChange({ credentials: newLoginCredentials }))
      dispatch(passwordRequestDataChange({ data: newFormData }))
      dispatch(registerCredentialsChange({ credentials: newRegisterCredentials }))
    })
  }

  const getRedirectUrl = () => {
    let redirectUrl = ''
    const publicPath = window.__env__ ? window.__env__.PUBLIC_PATH || '' : ''
    const urlLanguage = intl.getUrlLanguage()

    if (!urlLanguage) {
      // There's no language in the URL.
      // In this case we add the language to the URL.
      redirectUrl = urlBasename + window.location.pathname
      redirectUrl = redirectUrl.replace(publicPath, '')
      redirectUrl += window.location.search
    } else if (urlLanguage !== language) {
      // There's a language in the URL, but it's different from the language we store.
      // This is because the user just changed the language of the site and we need to update the URL.
      // In this case we replace the language in the URL
      redirectUrl = window.location.pathname.replace(publicPath + '/' + urlLanguage, urlBasename)
      redirectUrl += window.location.search
    } else {
      // If the language of the URL equals the language we store and we still need to redirect,
      // it means that the user wants to go to a URL of a page that doesn't exist.
      // In this case we redirect to the home page.
      redirectUrl = urlBasename
    }

    return redirectUrl
  }

  const bannerHasContent = (portalBannerContent) => {
    if (portalBannerContent && typeof portalBannerContent === 'string') {
      const divElement = document.createElement('div')
      divElement.innerHTML = portalBannerContent
      let textContent = divElement.textContent
      textContent = textContent.replace(/\u200B/g, '') // Remove 'Zero Width Space' characters, if any
      return !!textContent && !!textContent.trim()
    } else {
      return false
    }
  }

  const closeBanner = () => {
    const newCompanyData = { ...companyData }
    newCompanyData.portal_banner = ''
    dispatch(companyDataChange({ data: newCompanyData }))
  }

  const shouldDisplayPortalBanner = () => {
    const loginPageIsDisplayed = !accountId && (location.pathname === urlBasename || location.pathname === urlBasename + '/')
    const registerPageIsDisplayed = !accountId && location.pathname.includes('/register')

    if (loginPageIsDisplayed) {
      return bannerHasContent(_companyData.portal_banner) && utils.checkPermission('login_banner_view')
    } else if (registerPageIsDisplayed) {
      return bannerHasContent(_companyData.portal_banner) && utils.checkPermission('register_banner_view')
    } else {
      return false
    }
  }

  return (
    <main id="app-body" className={classes.wrapper}>
      {/* This element adds a spacing equal to the height of the header, so that the top part of the body isn't hidden. */}
      <div className={classes['header-spacing']}></div>

      {shouldDisplayPortalBanner() && (
        // Banner is only displayed on login page (and only if it has any content)
        <Paper className={classes.banner}>
          <Tooltip title={intl.translate('general__close')}>
            <IconButton className={classes['banner-close']} aria-label={intl.translate('general__close_banner')} onClick={closeBanner}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="body2" dangerouslySetInnerHTML={{ __html: _companyData.portal_banner }}></Typography>
        </Paper>
      )}

      <div id="app-body--content" className={classes.content}>
        {accountId ? (
          /* The user is logged in */
          <Switch>
            <Route exact path={urlBasename} component={AccountDetailsPage} />
            {utils.checkPermission('notifications_page_view') && (
              <Route exact path={urlBasename + '/notifications'} component={AccountNotificationsPage} />
            )}
            {utils.checkPermission('locations_page_view') && (
              <Route exact path={urlBasename + '/locations'} component={AccountLocationsPage} />
            )}
            <Route exact path={urlBasename + '/emails'} component={AccountEmailsPage} />
            <Route exact path={urlBasename + '/telephones'} component={AccountPhonesPage} />
            
            {utils.checkPermission('relatives_page_view') && (
              <Route exact path={urlBasename + '/relatives'} component={AccountIndividualsPage} />
            )}
            {utils.checkPermission('groups_page_view') && (
              <Route exact path={urlBasename + '/groups'} component={AccountGroupsPage} />
            )}
            <Route exact path={urlBasename + '/faq'} component={FaqPage} />
            {accountDetailsData.isFromSSO !== true && (
              <Route exact path={urlBasename + '/change-password'} component={AccountPasswordPage} />
            )}
            {utils.checkPermission('unsubscribe_page_view') && (
              <Route exact path={urlBasename + '/unsubscribe'} component={AccountDeregisterPage} />
            )}
            <Route exact path={urlBasename + '/accessibility-statement'} component={AccessibilityStatementPage} />
            <Route exact path={urlBasename + '/sitemap'} component={SitemapPage} />
            <Redirect to={getRedirectUrl()} />
          </Switch>
        ) : (
          /* There's no user logged in. Redirect to the login page. */
          <Switch>
            <Route exact path={urlBasename} component={LoginPage} />
            {utils.checkPermission('register_page_view') && <Route exact path={urlBasename + '/register'} component={RegisterPage} />}
            {utils.checkPermission('register_page_view') && <Route exact path={urlBasename + '/registerpublic'} component={RegisterPage} />}
            <Route exact path={urlBasename + '/new-password'} component={PasswordRequestPage} />
            <Route exact path={urlBasename + '/reset-password'} component={PasswordResetPage} />
            <Route exact path={urlBasename + '/faq'} component={FaqPage} />
            <Route exact path={urlBasename + '/accessibility-statement'} component={AccessibilityStatementPage} />
            <Route exact path={urlBasename + '/sitemap'} component={SitemapPage} />
            <Redirect to={getRedirectUrl()} />
          </Switch>
        )}
      </div>
      {utils.checkPermission('app_banner_view') && !isHidden && (
        <MobileView>
          <div className={classes['banner-app']}>
            <div className={classes['banner-app-close']} onClick={() => setIsHidden(true)}>
              <CloseIcon />
            </div>
            <div className={classes['banner-app-text-container']}>
              <h1 className={classes['banner-app-text']}>GEM - APP</h1>
              <p>{intl.translate('portal_banner_app_text')}</p>
            </div>
            <a href={companyData?.portal_banner_app_url} className={classes['banner-app-button']}>
              {intl.translate('portal_banner_app_button')}
            </a>
          </div>
        </MobileView>
      )}
    </main>
  )
}

export default AppBody
