import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import intl from "../../../helper/intl";
import BasicDialog from "../BasicDialog";
import { logout } from "../../../actions/authAction";
import { EXPIRED_SESSION_FLAG } from "../../../constants";

const SessionExpiredDialog = () => {

  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useDispatch();
  const { search } = useLocation();

  const handleLogout = () => {
    dispatch(logout());
    window.location.href = `${window.location.origin}${window.location.pathname}`;
  };

  useEffect(() => {
    setOpenDialog(location.search.includes(EXPIRED_SESSION_FLAG));
  }, [search]);

  return (
    <BasicDialog
      isOpen={openDialog}
      title={intl.translate('general__info')}
      description={intl.translate('general_expired_session')}
      actionButtons={[{ content: "OK", onClick: handleLogout }]}
    />
  );
}

export default SessionExpiredDialog;
