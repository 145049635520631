import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      marginRight: 0,
      paddingRight: theme.spacing(1),
      borderRadius: '2px',

      '& .Mui-checked .MuiSvgIcon-root': {
        fill: '#295BFF'
      }
    },
    wrapperFocused: {
      outline: '2px solid #295BFF'
    },
    wrapperReadOnly: {
      opacity: 0.75,
    }
  }),
  { name: 'checkbox-input-' }
)

export default useStyles
