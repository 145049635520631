import axios from "axios";
import { EXPIRED_SESSION_FLAG } from "../constants";

const errorHandlers = new Map([
  [401, () => {
    if (!window.location.search.includes(EXPIRED_SESSION_FLAG)) {
      window.location.href = window.location.href + '?' + EXPIRED_SESSION_FLAG;
    }
  }],
]);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    errorHandlers.get(error.response.status)?.();
    return Promise.reject(error);
  }
);

export default axios;
