import { useEffect } from 'react'
import { Paper, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import PageTitle from '../common/PageTitle'
import ButtonWithProgress from '../common/ButtonWithProgress'

import accountService from '../../service/accountService'
import intl from '../../helper/intl'

import { useSelector, useDispatch } from 'react-redux'
import { openAppSnackbar, closeAppSnackbar } from '../../actions/notificationsAction'
import { accountDetailsChange, updateAccountDetailsFailure, updateAccountDetailsStart, updateAccountDetailsSuccess } from '../../actions/accountDetailsAction'
import CheckboxInputTree from '../common/CheckboxInputTree'


const AccountGroupsPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const details = useSelector((state) => state.accountDetails.details)
  const updateDetailsLoading = useSelector((state) => state.accountDetails.updateDetailsLoading)
  const dispatch = useDispatch()  

  useEffect(() => {
    document.title =
      intl.translate('account_groups_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  const groupInputsChange = (inputs) => {
    const accountDetailsTags = [ ...private_tags, ...inputs.filter((input) => input.checked === true) ]
    dispatch(accountDetailsChange({ 
      details: { ...details, tags: accountDetailsTags }
    }))
  }

  const onFormSubmit = (event) => {
    event.preventDefault()

    dispatch(updateAccountDetailsStart())

    accountService
      .updateDetails({ accountId, details })
      .then((result) => {
        dispatch(updateAccountDetailsSuccess())
        dispatch(openAppSnackbar({ message: intl.translate('account_details_page__update_success') }))
      })
      .catch((error) => {
        dispatch(updateAccountDetailsFailure({ error: intl.translate('account_details_page__update_error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('account_details_page__update_error'),
            actionText: intl.translate('general__retry'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              document.getElementById('ccount-groups-page--update-btn').click()
            }
          })
        )
      })
  }

  // Get public tags from company data
  const public_tags = companyData?.tags?.filter((tag) => tag.publicTag === true) || [];
  const groupInputs = public_tags.map((tag) => {
    const selectedTag = details.tags.find((detailsTag) => detailsTag.id === tag.id)
    return {
      ...tag,
      value: selectedTag ? selectedTag.id : tag.id,
      checked: !!selectedTag,
      label: tag.tag,
    }
  })
  const private_tags = details?.tags?.filter((tag) => !public_tags?.map(t => t.id).includes(tag.id)) || []

  return (
    <Paper className="_centered_container_">
      <PageTitle>{intl.translate('account_groups_page__title')}</PageTitle>

      <Typography variant="body1" paragraph mt={2}>
        {intl.translate('account_groups_page__description')}
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>{intl.translate('description_note')}: </strong>
        {intl.translate('account_groups_page__tip')}
      </Typography>

      <form onSubmit={onFormSubmit}>
        <CheckboxInputTree
          id="group-inputs"
          legend=""
          disabled={updateDetailsLoading}
          showAllInput={false}
          parentIdParam="parent"
          nodes={groupInputs}
          onChange={(inputs) => { groupInputsChange(inputs) }}
        />
        <div className="_centered_btn_container_">
          <ButtonWithProgress
            type="submit"
            id="account-groups-page--update-btn"
            showProgress={updateDetailsLoading}
            variant="contained"
            color={window.app.theme.palette.button ? 'button' : 'primary'}
            size="large"
          >
            {intl.translate('account_details_page__submit_btn')}
            <Typography style={visuallyHidden}> {intl.translate('account_groups_page__title')}</Typography>
          </ButtonWithProgress>
        </div>
      </form>
    </Paper>
  )
}

export default AccountGroupsPage
