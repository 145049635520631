import accountService from '../service/accountService'
import { mfaUserDataChange } from './authAction'
import { loadAccountDetailsFailure, loadAccountDetailsStart, loadAccountDetailsSuccess } from './accountDetailsAction'
import { loadAccountEmailsFailure, loadAccountEmailsStart, loadAccountEmailsSuccess } from './accountEmailsAction'
import { loadAccountIndividualsFailure, loadAccountIndividualsStart, loadAccountIndividualsSuccess } from './accountIndividualsAction'
import { loadAccountLocationsFailure, loadAccountLocationsStart, loadAccountLocationsSuccess } from './accountLocationsAction'
import {
  loadAccountNotificationsFailure,
  loadAccountNotificationsStart,
  loadAccountNotificationsSuccess
} from './accountNotificationsAction'
import { loadAccountPhonesFailure, loadAccountPhonesStart, loadAccountPhonesSuccess } from './accountPhonesAction'

export const loadAllAccountDataAsync =
  ({ accountId }) =>
  (dispatch) => {
    dispatch(loadAccountDetailsStart())
    dispatch(loadAccountEmailsStart())
    dispatch(loadAccountPhonesStart())
    dispatch(loadAccountIndividualsStart())
    dispatch(loadAccountLocationsStart())
    dispatch(loadAccountNotificationsStart())

    accountService
      .getAllData({ accountId })
      .then((result) => {
        // Transform API data to the format used by our app's components
        const details = {
          id: result.data.id || '',
          firstName: result.data.firstnames || '',
          lastName: result.data.surnames || '',
          language: result.data.lang || '',
          email: result.data.email || '',
          emailConfirmed: typeof result.data.email_confirmed === 'boolean' ? result.data.email_confirmed : true,
          countryCode: result.data.country_code ? result.data.country_code.code || '' : '',
          phoneNumber: result.data.number || '',
          phoneNumberConfirmed: typeof result.data.number_confirmed === 'boolean' ? result.data.number_confirmed : true,
          phoneNumberSms: result.data.phone_type === 'true',
          externalId: result.data.externalid || '',
          tags: result.data.tags || [],
          password: '',
          newPassword: '',
          newPasswordConfirm: '',
          passwordUpdateNeeded: result.data.update_password === true,
          isFromSSO: result.data.isFromSSO
        }

        const customFields = result.data.customFields || []

        const emails = result.data.emails || []
        const numEmails = emails.length
        for (let i = 0; i < numEmails; i++) {
          emails[i].emailConfirmed = emails[i].email_confirmed
          delete emails[i].email_confirmed
        }

        const phones = result.data.phones || []
        const numPhones = phones.length
        for (let i = 0; i < numPhones; i++) {
          phones[i].phoneNumber = phones[i].number
          delete phones[i].number
          phones[i].countryCode = phones[i].country_code
          delete phones[i].country_code
          phones[i].phoneNumberSms = phones[i].phone_type === 'true'
          delete phones[i].phone_type
          phones[i].phoneNumberConfirmed = phones[i].number_confirmed
          delete phones[i].number_confirmed
        }

        const individuals = result.data.relatives || []
        const numIndividuals = individuals.length
        for (let i = 0; i < numIndividuals; i++) {
          individuals[i].phoneNumber = individuals[i].number
          delete individuals[i].number
          individuals[i].countryCode = individuals[i].country_code
          delete individuals[i].country_code
          individuals[i].phoneNumberSms = individuals[i].phone_type === 'true'
          delete individuals[i].phone_type
          individuals[i].emailConfirmed = individuals[i].email_confirmed
          delete individuals[i].email_confirmed
          individuals[i].phoneNumberConfirmed = individuals[i].number_confirmed
          delete individuals[i].number_confirmed
        }

        const locations = result.data.locations || []
        const numLocations = locations.length
        for (let i = 0; i < numLocations; i++) {
          locations[i].postcode = locations[i].zipCode
          delete locations[i].zipCode
          locations[i].longitude = locations[i].longitude || ''
          locations[i].latitude = locations[i].latitude || ''
        }

        const notificationTypesRaw = result.data.notification_types || []
        const notificationTypes = []
        for (let i = 0; i < notificationTypesRaw.length; i++) {
          notificationTypes.push({
            value: notificationTypesRaw[i].id,
            checked: notificationTypesRaw[i].active,
            label: notificationTypesRaw[i].name
          })
        }

        const notificationChannelsRaw = result.data.notification_channels || []
        const notificationChannels = []
        for (let i = 0; i < notificationChannelsRaw.length; i++) {
          notificationChannels.push({
            value: notificationChannelsRaw[i].id,
            checked: notificationChannelsRaw[i].active,
            label: notificationChannelsRaw[i].name,
            sequenceNumber: notificationChannelsRaw[i].sequenceNumber || i + 1
          })
        }
        notificationChannels.sort((a, b) => a.sequenceNumber - b.sequenceNumber)

        const weatherTypesRaw = result.data.weather_categories || []
        const weatherTypes = []
        for (let i = 0; i < weatherTypesRaw.length; i++) {
          weatherTypes.push({
            id: weatherTypesRaw[i].id,
            checked: weatherTypesRaw[i].active,
            label: weatherTypesRaw[i].name,
            parent_id: weatherTypesRaw[i].parent_id
          })
        }
        weatherTypes.sort((a, b) => a.id - b.id)

        dispatch(loadAccountDetailsSuccess({ details, customFields }))
        dispatch(mfaUserDataChange({ email: details.email, countryCode: details.countryCode, phoneNumber: details.phoneNumber }))
        dispatch(loadAccountEmailsSuccess({ emails }))
        dispatch(loadAccountPhonesSuccess({ phones }))
        dispatch(loadAccountIndividualsSuccess({ individuals }))
        dispatch(loadAccountLocationsSuccess({ locations }))
        dispatch(loadAccountNotificationsSuccess({ types: notificationTypes, channels: notificationChannels, weatherTypes }))
      })
      .catch((error) => {
        dispatch(loadAccountDetailsFailure({ error: '' }))
        dispatch(loadAccountEmailsFailure({ error: '' }))
        dispatch(loadAccountPhonesFailure({ error: '' }))
        dispatch(loadAccountIndividualsFailure({ error: '' }))
        dispatch(loadAccountLocationsFailure({ error: '' }))
        dispatch(loadAccountNotificationsFailure({ error: '' }))
        console.error(error)
      })
  }
